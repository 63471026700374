import React, { useContext, useEffect} from 'react'
import { MainHeaderContext } from '../components/main-header/main-header-state'

import './main-app.css'
import Menu from '../components/menu/menu'
import MenuEntry from '../components/menu/menu-entry'
import DashboardIcon from '../components/icons/dashboard-icon'

import { Switch, Route } from 'react-router-dom'

import '../../node_modules/chartist/dist/chartist.min.css'
import Keycloak from './keycloak'
import StatusPage from './pages/status/status-page'


function getClasses(header){

    return header.hidden.value    ? '' :
           header.collapsed.value ? 'with-header-collapsed' :
           header.extended.value  ? 'with-header-extended' : 
                                    'with-header'
}

export default function MainApp(){

    let headerState = useContext(MainHeaderContext)

    useEffect(() => {
        Notification.requestPermission()
    })

    return <Keycloak>

        <div id='main-app' className={getClasses(headerState)}>

            <Menu onLogout={() => {console.log('logout')}}>

                <MenuEntry IconComponent={DashboardIcon} label='Status' description='Veja o status dos serviços' onClick={()=>{}}></MenuEntry>

            </Menu>

            <Switch>
                <Route path='/' component={StatusPage}></Route>
            </Switch>

        </div>

    </Keycloak>

}




