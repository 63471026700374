import React, { useContext, useEffect } from 'react'

import './main-header.css'
import logo from './intelbras-brand.svg'
import menuIcon from './ic-menu.svg'
import ChevronLeftIcon from '../icons/chevron-left'
import notification from './ic-notification-empty.svg'
import { useLocation, useHistory } from 'react-router-dom'
import { MainHeaderContext } from './main-header-state';
import { MenuContext } from '../menu/menu-state';
import { NotificationsContext } from '../notifications/notifications-state'

export function animateNotifyIcon() {

    let icon = document.getElementById('header-notify-icon')
    icon.classList.add('animate')
    setTimeout(() => {
        icon.classList.remove('animate')
    }, 2000)
}


function getClasses(state) {


    return `${state.extended.value ? 'extended' : ''}`
}

// const HEADER_COLLAPSE_TRESHOLD = 100

export default function MainHeader() {

    let state = useContext(MainHeaderContext)
    let menu = useContext(MenuContext)
    let notificationsState = useContext(NotificationsContext)
    let location = useLocation()
    let history = useHistory()


    useEffect(() => {


        if(notificationsState.notifications.value.length !== 0)
            animateNotifyIcon()

        // eslint-disable-next-line 
    }, [notificationsState])

    return (
        state.hidden.value ? '' : <div id='main-header' className={
            getClasses(state)
        }>
            <div className='main-header-top'>
                
                {state.navigation.value ? <button id='menu-button' className='clickable' onClick={ ()=> menu.open.set(!menu.open.value) }>
                    <img alt='Menu' src={menuIcon}></img>
                </button> : <div></div>}
                        
                <div className='main-header-logo'>
                    <img alt='intelbras' src={logo}></img>
                </div>

                <div id='header-notify-icon' className='main-header-notify-icon'>
                    {notificationsState.notifications.value.length !== 0 ? <div className='main-header-notify-number'>
                        {notificationsState.notifications.value.length}
                    </div> : null}

                    { state.notifications.value ? <img src={notification} alt='Notificações' className='main-header-notify-icon'
                        onClick={ () => {
                            if(notificationsState.notifications.value.length !== 0)
                                notificationsState.open.set(true)
                        }}
                    ></img> : null}
                </div>

            </div>
            <div className='main-header-title'>
            
                {
                    
                    location.pathname === '/' || location.pathname.includes('/&') ? '' : <div className='clickable' id='menu-return-button'
                            onClick={() => {

                                if(state.backRoute.value === history.location.pathname){
                                    history.push('/')
                                    return
                                }
                                
                                history.push(state.backRoute.value)

                            }}
                        >
                            {state.navigation.value ? <ChevronLeftIcon size='17'  className='clickable'></ChevronLeftIcon> : null }
                        </div>
                }

            <span>{
                    !state.headerExtended ? state.title.value : ''
                }</span>
            </div>

        </div>
    )
}