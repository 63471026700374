import React from 'react'

import SearchIcon from '../icons/search-icon'

import './infocard.css'

const InfoCard = ({id, lines}) => {

    return <div id={id} className='info-card'>
        {
            lines.map((line, key) => {
                return <div key={key}
                    onClick={line.withDetails ? line.onClick : null}
                    className={`info-card-line ${key % 2 !== 0 ? 'odd' : ''} ${line.withDetails ? 'clickable' : ''} ${line.large ? 'large' : ''}`}
                    style={line.hidden ? {display:'none'} : {}}
                >
                    
                    <label>{line.label}</label>
                    <span className={line.warning ? 'warning' : ''} id={line.id}>{line.value}</span>

                    <div className='info-card-line-icon'>
                        {line.withDetails && <SearchIcon size='18'></SearchIcon>}
                    </div>
                </div>
            })
        }

    </div>
}

export default InfoCard