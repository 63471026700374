import { backendStatus } from '../backend'
import httpStatus from 'http-status'

export default class RestDriver{

    constructor(){
        this.baseUrl = process.env.REACT_APP_REST_ENDPOINT
    }

    setBaseUrl(url){
        this.baseUrl = url
    }

    getBaseUrl(){
        return this.baseUrl
    }

    async handleResult(result){

        if(result.status === httpStatus.UNAUTHORIZED) 
            return {
                status: backendStatus.UNAUTHORIZED,
                content: {}
            }

        let response = await result.text()
        if(response !== '')
            try{
                response = await JSON.parse(response)
            }catch{
                console.warn('not json on rest response')
            }

        let status = result.status === httpStatus.OK ? backendStatus.SUCCESS : backendStatus.ERROR


        if(status === backendStatus.ERROR){
            response = result.status
        }

        return {
            status: status,
            content: response,
            headers: result.headers
        }

       
    }

    handleAuthHeaders(headers, auth){

        headers = !headers ? {} : headers

        if(auth)
            headers['Authorization'] = `Bearer ${auth.token}`

        return headers
    }

    async fetchWithData(resourceUrl, data, method, headers, auth){

        headers = this.handleAuthHeaders(headers, auth)

        let result = await fetch(`${this.baseUrl}/${resourceUrl}`, {
            method: method,
            body: JSON.stringify(data),
            headers: headers
        })

        return this.handleResult(result)
    }

    async create(resourceUrl, data, headers, auth){

        return await this.fetchWithData(resourceUrl, data, 'POST', headers, auth)
    }

    async upload(resourceUrl, data, headers, auth){

        headers = this.handleAuthHeaders(headers, auth)

        console.log(headers, auth)

        let result = await fetch(`${this.baseUrl}/${resourceUrl}`, {
            method: 'POST',
            body: data,
            headers: headers
        })

        return this.handleResult(result)
    }

    async retrieve(resourceUrl, auth) {

        let headers = {'Cache-Control': 'no-cache'}

        if(auth)
            headers['Authorization'] = `Bearer ${auth.token}`
        
        let result = await fetch(`${this.baseUrl}/${resourceUrl}`, {
            method: 'GET',
            headers: headers
        })

        return this.handleResult(result)
    }

    async update(resourceUrl, data, headers, auth) {

        return await this.fetchWithData(resourceUrl, data, 'PUT', headers, auth)
    }

    async delete(resourceUrl, data, auth) {

        let headers = {}

        if(auth)
            headers['Authorization'] = `Bearer ${auth.token}`

        let result = await fetch(`${this.baseUrl}/${resourceUrl}`, {
            method: 'DELETE',
            body: JSON.stringify(data),
            headers: headers         
        })

        return this.handleResult(result)
    }
}