import React, { useEffect, useContext, useState } from 'react'

import noAvatar from '../images/no-avatar.png'
import { MenuContext } from '../components/menu/menu-state'
import { BackendContext } from '../backend/backend'

import KeycloakJS from 'keycloak-js'
import Loading from '../components/loading/loading'

const TOKEN_UPDATE_TIME = 20000

export const KeycloakContext = React.createContext()



export default function Keycloak({children}) {

    const [kc, setKc] = useState({})
    const [authenticating, setAuthenticating] = useState(true)

    const menu = useContext(MenuContext)
    const backend = useContext(BackendContext)

    useEffect(() => {

        if(process.env.NODE_ENV === 'development')
            return

        console.log(process.env.REACT_APP_KEYCLOAK_ADDRESS)

        setAuthenticating(true)

        let keycloak = KeycloakJS({
            url: `${process.env.REACT_APP_KEYCLOAK_ADDRESS}/auth`,
            clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
            realm: process.env.REACT_APP_KEYCLOAK_REALM
        })

        keycloak.onAuthError = (error) => {
            console.log('Error autenticating')

            alert(error)
        }

        let updateInterval = null

        keycloak.init({onLoad:'login-required'}).success((authenticated) => {

            console.log('autenticado')

            localStorage.setItem('auth', 'true')

            keycloak.loadUserProfile().success((profile) => {

                console.log(profile)

                menu.username.set(profile.firstName)
                menu.avatar.set(noAvatar)

                setAuthenticating(false)

            })

            setAuthenticating(false)

            updateInterval = setInterval(() => {
                keycloak.updateToken(30).error((error) => console.log('Erro ao atualizar o token:', error))
            }, TOKEN_UPDATE_TIME)
        })


        backend.setAuthInfo(keycloak)

        setKc(keycloak)
        // eslint-disable-next-line

        return () => {
            clearInterval(updateInterval)
        }

        // eslint-disable-next-line 
    }, [])

//    return null

    if(process.env.NODE_ENV === 'development')
        return children

    return <KeycloakContext.Provider value={kc}>
                {authenticating ? <Loading show={true}></Loading> : children}
           </KeycloakContext.Provider>

    // return children
}
