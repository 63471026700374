import React, { useState } from 'react'

export const MainHeaderContext = React.createContext()

export default function MainHeaderGlobalState({children}){

    const [title, setTitle] = useState('')
    const [backRoute, setBackRoute] = useState('')
    const [extended, setExtended] = useState(false)
    const [collapsed, setCollapsed] = useState(false)
    const [hidden, setHidden] = useState(false)
    const [navigation, setNavigation] = useState(true)
    const [notifications, setNotifications] = useState(true)

    return <MainHeaderContext.Provider value={{
        title: {value: title, set: setTitle},
        hidden: {value: hidden, set: setHidden},
        extended: {value: extended, set: setExtended},
        collapsed: {value: collapsed, set: setCollapsed},
        backRoute: {value: backRoute, set: setBackRoute},
        navigation: {value: navigation, set: setNavigation},
        notifications: {value: notifications, set: setNotifications}
    }}>
        {children}
    </MainHeaderContext.Provider>
}