import './status.css'
import React, { useEffect, useContext, useState } from 'react'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import Loading from '../../../components/loading/loading'
import CardButton from '../../../components/cardbutton/cardbutton'
import RemotizeIcon from '../../../components/icons/remotize/remotize-icon'
import NetworksIcon from '../../../components/icons/networks-icon'
import DeviceIcon from '../../../components/icons/device'
import InfoCard from '../../../components/infocard/infocard'
import ClockIcon from '../../../components/icons/clock-icon'
import io from 'socket.io-client'
import RemotizeImg from '../../../components/icons/remotize/remotize.png'
import ChartistGraph from 'react-chartist'

const apiUrl = process.env.REACT_APP_REST_ENDPOINT

export default function StatusPage() {

    const [status, setStatus] = useState(null)
    const [total, setTotal] = useState([])
    const [online, setOnline] = useState([])
    const [associated, setAssociated] = useState([])
    const [restTime, setRestTime] = useState([])
    const [upStates, setUpStates] = useState({
        mqtt: [],
        rest: [],
        web: [],
    })


    const header = useContext(MainHeaderContext)

    useEffect(() => {
        header.title.set('Remotize status')

        fetchStatus()

        let socket = io(apiUrl)
        let serviceWorker = null;
        navigator.serviceWorker.ready.then(sw => serviceWorker = sw)

        socket.on('status', () => {
            fetchStatus()
        })
        socket.on('failure', () => {
            serviceWorker.showNotification('O remotize está reportando falhas na infra estrutura', {icon: RemotizeImg})
            fetchStatus()
        })
        socket.on('alive', () => {
            serviceWorker.showNotification('O remotize voltou a operar normalmente', {icon: RemotizeImg})
            fetchStatus()
        })
        socket.on('healing', () => {
            serviceWorker.showNotification('A rotina de recuperação do remotize foi iniciada', {icon: RemotizeImg})
            fetchStatus()
        })
        socket.on('healing-done', () => {
            serviceWorker.showNotification('A rotina de recuperação do remotize foi finalizada', {icon: RemotizeImg})
            fetchStatus()
        })

        return () => socket.close()

        // eslint-disable-next-line
    }, [])

    const fetchStatus = async() => {

        let result = await fetch(`${apiUrl}/api/status`)
        let data = await result.json()

        total.push(data.rest.statistics.stats.total)
        setTotal([...total])

        online.push(data.rest.statistics.stats.online)
        setOnline([...online])

        associated.push(data.rest.statistics.stats.associated)
        setAssociated([...associated])

        restTime.push(data.rest.time)
        setRestTime([...restTime])

        upStates.mqtt.push(data.mqtt.status)
        upStates.rest.push(data.rest.status)
        upStates.web.push(data.web.status)
        setUpStates({...upStates})

        setStatus(data)
    }


    const composeTime = (value) => {

        let text = Math.round(value / 60) + ':'
        let rest = value % 60
        text += (rest < 10 ? '0' : '') + rest + 'h'

        return text
    }

    return !status ? <Loading show={true}></Loading> : <div id='status-page'>

        <div className='flex-container'>

            <CardButton
                Icon={RemotizeIcon}
                iconSize={30}
                label={'Conectividade mqtt'}
                value={status.mqtt.status ? 'Online' : 'Offline'}
            ></CardButton>

            <CardButton
                Id='icon-rest'
                Icon={NetworksIcon}
                iconSize={30}
                label={'Conectividade rest'}
                value={status.rest.status ? 'Online' : 'Offline'}
            ></CardButton>

            <CardButton
                Id='icon-web'
                iconColor={'orange'}
                Icon={DeviceIcon}
                iconSize={30}
                label={'Servidor Web'}
                value={status.web.status ? 'Online' : 'Offline'}
            ></CardButton>

            <CardButton
                Icon={RemotizeIcon}
                iconSize={30}
                label={'SLA'}
                value={`${status.monitor.sla.toFixed(3)}%`}
            ></CardButton>

        </div>

        <div className='status-info-graph'>
            <div className='subtitle'>
                <ClockIcon size={20}></ClockIcon>
                <span>Dispositivos</span>
            </div>
            <div id='status-graph'>
                <div id='status-graph-legend'>
                    <div id='total' className='legend'>
                        <label>Total:</label>
                        <span>{status.rest.statistics.stats.total}</span>
                    </div>
                    <div id='online' className='legend'>
                        <label>Online:</label>
                        <span>{status.rest.statistics.stats.online}</span>
                    </div>
                    <div id='associated' className='legend'>
                        <label>Associados:</label>
                        <span>{status.rest.statistics.stats.associated}</span>
                    </div>
                </div>
                <ChartistGraph
                    data={{series:[total, online, associated]}}
                    type='Line'
                    options={{showPoint: false}}
                ></ChartistGraph>
            </div>
        </div>

        <div className='status-info-graph'>
            <div className='subtitle'>
                <ClockIcon size={20}></ClockIcon>
                <span>Histórico de status</span>
            </div>
            <div id='status-graph'>
                <div id='status-graph-legend'>
                    <div id='total' className='legend'>
                        <label>MQTT</label>
                        <span>{status.mqtt.status}</span>
                    </div>
                    <div id='online' className='legend'>
                        <label>Rest</label>
                        <span>{status.rest.status}</span>
                    </div>
                    <div id='associated' className='legend'>
                        <label>Web</label>
                        <span>{status.web.status}</span>
                    </div>
                </div>

                <ChartistGraph
                    data={{series:[upStates.mqtt, upStates.rest, upStates.web]}}
                    type='Line'
                    options={{showPoint: false}}
                ></ChartistGraph>
            </div>
        </div>


        <div className='status-info-graph'>
            <div className='subtitle'>
                <ClockIcon size={20}></ClockIcon>
                <span>Tempo de resposta da API</span>
            </div>
            <div id='status-graph'>
                <ChartistGraph
                    data={{series:[restTime]}}
                    type='Line'
                    options={{showPoint: false}}
                ></ChartistGraph>
            </div>
        </div>


        <div className='flex-container'>

            <div className='status-info-card'>

                <div className='subtitle'>
                    <ClockIcon size={20}></ClockIcon>
                    <span>Tempos de resposta</span>
                </div>

                <InfoCard
                    lines={[
                        {label: 'Novas conexões', value: `${status.mqtt.times.remotize} ms`},
                        {label: 'Teste REST', value: `${status.rest.time} ms`},
                        {label: 'Remotize Web', value: `${status.web.time} ms`},
                    ]}
                ></InfoCard>

            </div>

            <div className='status-info-card'>

                <div className='subtitle'>
                    <ClockIcon size={20}></ClockIcon>
                    <span>Status dos testes</span>
                </div>


                <InfoCard
                    lines={[
                        {label: 'Base de dados', value: status.rest.statistics.database ? 'Online' : 'Offline'},
                        {label: 'Redis', value: status.rest.statistics.redis ? 'Online' : 'Offline'},
                        {label: 'Período de teste', value: composeTime(status.monitor.totalTime)},
                        {label: 'Período offline', value: composeTime(status.monitor.downTime)},
                        {label: 'Rotina de recuperação', value: status.monitor.healing ? 'Recuperando' : 'Monitorando'},
                    ]}
                ></InfoCard>

            </div>

        </div>

    </div>
}
