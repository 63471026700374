import React, { useState } from 'react'

export const MenuContext = React.createContext()

export default function MenuGlobalState({children}) {

    const [open, setOpen] = useState(false)
    const [username, setUsername] = useState('')
    const [avatar, setAvatar] = useState('')

    return <MenuContext.Provider value={{
        open: {value: open, set: setOpen},
        username: {value: username, set: setUsername},
        avatar: {value: avatar, set: setAvatar},
    }}>
        {children}
    </MenuContext.Provider>
}