import React, { useContext } from 'react'

import './menu.css'
import { MenuContext } from './menu-state';
import noAvatar from '../../images/no-avatar.png'
import Overlay from '../overlay/overlay';

export default function Menu({onLogout, children}) {

    let state = useContext(MenuContext)

    return (
        <React.Fragment>
            <Overlay open={state.open.value} onClick={() => state.open.set(false)}></Overlay>
            <div id='menu-container' className={state.open.value ? 'open' : ''}>

                <div className='menu-content'>

                    <div className='menu-user'>
                        <div className='menu-user-picture'>
                            <img src={state.avatar.value !== '' ? state.avatar.value : noAvatar} 
                                width='80'
                                alt={state.username}
                            ></img>
                        </div>
                        <span className='menu-user-name'>
                            {state.username.value}
                            {onLogout ? <div className='menu-logout' 
                                onClick={onLogout}
                            >
                                (<span>sair</span>)
                            </div> : null}
                        </span>
                    </div>

                    <div id='menu-area' className='menu-entries'>
                        {children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}