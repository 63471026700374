import React from 'react'

export default function DeviceIcon({size, color}) {

    return <svg version="1.1" width={size} viewBox="0 0 480.097 480.097" >
<g>
	<path fill={color} d="M206.759,332.99H39.225c-3.894,0-7.065-3.173-7.065-7.067V53.353c0-3.893,3.172-7.065,7.065-7.065
		h401.631c3.895,0,7.065,3.172,7.065,7.065v59.696c11.763,1.389,22.785,5.244,32.159,11.581V53.353
		c0-21.63-17.602-39.225-39.225-39.225H39.225C17.602,14.128,0,31.722,0,53.353v272.569c0,21.631,17.602,39.227,39.225,39.227
		h134.62v52.581h-21.229c-13.316,0-24.12,10.796-24.12,24.12c0,13.324,10.804,24.12,24.12,24.12h67.71
		c-8.463-11.902-13.566-26.35-13.566-42.037V332.99z"/>
	<path fill={color} d="M341.739,441.645v-34.742h-70.662V184.999c0-4.681,3.8-8.489,8.479-8.489h159.887
		c4.679,0,8.478,3.808,8.478,8.489v68.676h12.046c7.364,0,14.179,2.127,20.113,5.597v-74.273c0-22.417-18.23-40.648-40.638-40.648
		H279.556c-22.407,0-40.638,18.231-40.638,40.648v238.933c0,22.417,18.23,40.647,40.638,40.647h69.438
		C344.566,458.22,341.739,449.359,341.739,441.645z"/>
	<path fill={color} d="M459.967,273.775h-77.996c-11.104,0-20.132,9.037-20.132,20.138v147.732
		c0,11.101,9.028,20.131,20.132,20.131h77.996c11.102,0,20.13-9.03,20.13-20.131V293.912
		C480.097,282.811,471.068,273.775,459.967,273.775z M385.958,297.894h70.019v127.703h-70.019V297.894z M420.977,451.993
		c-2.215,0-4.193-0.896-5.7-2.277c-1.713-1.555-2.812-3.739-2.812-6.228c0-4.694,3.801-8.495,8.512-8.495
		c4.679,0,8.479,3.801,8.479,8.495c0,2.489-1.1,4.672-2.795,6.228C425.152,451.098,423.174,451.993,420.977,451.993z"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>


}