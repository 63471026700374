import React, { useState } from 'react'
import BackendDriver from './drivers/rest'

export const backendStatus = {
    SUCCESS: 1,
    ERROR: 2,
    UNAUTHORIZED: 3,
}

export const BackendContext = React.createContext()

var data = {}
var driver = new BackendDriver()

export default function Backend({children}){

    const [auth, setAuth] = useState(null)
    const [authHandlerEnabled, setAuthHandlerEnabled] = useState(true)
    const [onUnauthorized, setOnUnauthorized] = useState(() => defaultUnauthorizedHandler)

    function defaultUnauthorizedHandler() {
        console.warn("Received an 'unauthorized' response from backend."  +
            "You can implement your own handler setting an 'onUnauthorized' " +
            "handler on backend. (backend.setUnauthorizedHandler(handlerFn)")

    }

    function setOnUnauthorizedHandler(handlerFn){
        setOnUnauthorized(() => handlerFn)
    }

    async function handleDriverResponse(driverResponse, key) {

        if(driverResponse.status === backendStatus.UNAUTHORIZED){

            if(authHandlerEnabled) onUnauthorized()
            return
        }

        if(driverResponse.status === backendStatus.SUCCESS)
            data[key] = driverResponse.content

    }

    async function create(key, data, headers) {//FIXME: turn headers into some kind of driver agnostic data

        let driverResponse = await driver.create(key, data, headers, auth)
        
        handleDriverResponse(driverResponse, key)

        return driverResponse
    }

    async function upload(key, data, headers) {//FIXME: turn headers into some kind of driver agnostic data

        let driverResponse = await driver.upload(key, data, headers, auth)
        
        handleDriverResponse(driverResponse, key)

        return driverResponse
    }

    async function retrieve(key) {

        if(!data[key])
            return await retrieveFresh(key)

        return {
            status: backendStatus.SUCCESS,
            content: data[key]
        }
    }

    async function retrieveFresh(key) {
        const driverResponse = await driver.retrieve(key, auth)

        handleDriverResponse(driverResponse, key)
        
        return driverResponse
    }

    async function update(key, value, headers){ //FIXME: turn headers into some kind of driver agnostic data
        
        const driverResponse = await driver.update(key, value, headers, auth)
            
        handleDriverResponse(driverResponse)

        if(driverResponse.status === backendStatus.SUCCESS && data[key])
            data[key] = value

        return driverResponse
    }

    async function backendDelete(key, value){ //FIXME: remove value parameter from APIs
         
        const driverResponse = await driver.delete(key, value, auth)
            
        handleDriverResponse(driverResponse)

        if(driverResponse.status === backendStatus.SUCCESS && data[key])
            delete data[key]

        return driverResponse       
    }

    async function flushCache(key) {

        if(key){
            delete data[key]
            return
        }

        data = {}
    }

    function setDriverBaseUrl(url) {
        driver.setBaseUrl(url)
    }

    function getDriverBaseUrl() {
        return driver.getBaseUrl()
    }

    return <BackendContext.Provider value={{

        create: create,
        retrieve: retrieve,
        retrieveFresh: retrieveFresh,

        update: update,

        delete: backendDelete,

        upload: upload,
        
        setOnUnauthorized: setOnUnauthorizedHandler,

        setAuthInfo: setAuth,

        flushCache: flushCache,

        setDriverBaseUrl: setDriverBaseUrl,
        getDriverBaseUrl: getDriverBaseUrl,

        setAuthHandlerEnabled: setAuthHandlerEnabled

    }}>{children}</BackendContext.Provider>
}